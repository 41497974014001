
import { Component, Vue } from 'vue-property-decorator'
import { Manipulator } from '@ht-web/canvas-image'

@Component({
  name: 'Resize Image Test'
})
export default class extends Vue {
  image!: HTMLImageElement
  blob: Blob
  url: string | null = null
  baseUrl = require('../assets/TEST_IMAGE.jpg')
  created () {
    this.image = new Image()
    this.image.src = this.baseUrl
    this.image.onload = async () => {
      this.blob = await new Manipulator(this.image).cropRotate(1000, 1.25)
      this.url = URL.createObjectURL(this.blob)
      console.log('hellooo')
    }
  }
}
